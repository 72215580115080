.heading{
    text-align: center;
}
.heading .img{
    width: 300px;
    margin-left: auto;
    margin-right: auto;
}
.heading .img img{

}
.heading h3{
    color: var(--text-color);
    /* transition-delay: 0.8s; */
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
}
.heading .img,
.heading h3{
    position: relative;
    transition: var(--main-transition);
    overflow: hidden;
}
.heading h3::before,
.heading .img::before{
    content: "";
    position: absolute;
    background-color: var(--main-color);
    right: 100%;
    top: 0;
    height: 100%;
    width: 100%;
    /* transform: translateX(-100%); */
    transition: var(--main-transition);
    transition-duration: 0.8s;
    z-index: 2;
}
.heading .img img,
.heading h3 span{
    opacity: 0; 
    /* filter: blur(50px); */
    transition: var(--main-transition);
    transition-delay: 0.5s;
    transition-duration: 0.5;
}
.heading h3::before{
}
.heading.animate .img img,
.heading.animate h3 span{
    opacity: 1;
    filter: blur(0px);
}
.heading.animate h3::before,
.heading.animate .img::before{
    right: -100%;
    width: 100%;
}   

.heading.animate .img img,
.heading.animate h3 span{
    opacity: 1; 
}
