.footer{
    background-color: var(--text-color);
    color: white;
    text-align: center;
    /* width: 250px; */
    margin-left: auto;
    margin-right: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
}
.footer p{
    background-color: black;
    padding: 10px 30px;
    border-radius: 20px 20px 0px 0px;
    height: 100%;
}
.footer a{
    
    color: var(--main-color);
    font-size: 16px;
    font-weight: bold;
    text-decoration: underline;
}