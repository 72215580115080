.services{
    background-color: var(--sec-bg-color);
    padding-top: 60px;
    padding-bottom: 60px;
}
.cards{
    display: grid;
    grid-template-columns: repeat(2,minmax(200px,1fr));
    perspective: 500px;
    gap: 20px;
    margin-top: 20px;
}

@media (max-width: 797px) {
    .cards{
        grid-template-columns: repeat(1,minmax(200px,500px));
        place-content: center;
    }
    
}
@media (max-width: 500px) {
    .cards{
        grid-template-columns: 350px;
        place-content: center;
    }
    
}
.cards .card{
    background-color: rgba(8, 7, 7, 0.2);
    /* background-color: transparent; */
    min-height: 5   00px;
    border-radius: 8px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: var(--main-transition);
    transition-duration: 0.5ss;
    perspective-origin: center center;
    box-shadow: var(--card-shadow);
    transition: var(--main-transition);
}
.cards .card:hover{
    box-shadow: var(--card-hover-shadow);
}
.cards .card:hover{
    transform: rotateX(var(--mouse-y-deg)) rotateY(var(--mouse-x-deg));
}
.cards .card .cardBorder,
.cards .card:before{
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transition: var(--main-transition);
    border-radius: inherit;
    opacity: 0;
}
.cards .card:before{
    background-image: radial-gradient(
        600px circle at var(--mouse-x) var(--mouse-y),
        var(--main-color),
        transparent 40%
    );
    z-index: 2;
}
.cards .card .cardBorder{
    background-image: radial-gradient(
        /* 800px circle at 100px 100px, */
        400px circle at var(--mouse-x) var(--mouse-y),
        var(--main-color) 20%,
        transparent 80%
    );
    z-index: 1;
}
.cards .card:hover::before,
.cards .card:hover .cardBorder{
    opacity: 1;
}
@media (max-width: 767px) {
    .cards .card .cardBorder,
    .cards .card:before{
        opacity: 1;
    }
    .cards .card:before{
        background-image: radial-gradient(
            600px circle at 100px 300px,
            var(--main-color),
            transparent 40%
        );
        z-index: 2;
    }
    .cards .card .cardBorder{
        background-image: radial-gradient(
            /* 800px circle at 100px 100px, */
            400px circle at 100px 300px,
            var(--main-color) 20%,
            transparent 80%
        );
        z-index: 1;
    }
}
.cards .card .cardContent{
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    border-radius: 8px;
    background-color: var(--card-content-bg);
    /* background-color: transparent; */
    color: var(--text-color);
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: space-evenly; */
    position: relative;
    text-align: center;
    padding: 20px;
    z-index: 2;
}
.cards .card .cardContent h3{
    font-size: 22px;
}
.cards .card:nth-child(1) .cardContent span{
    width: 200px;
}
.cards .card:nth-child(2) .cardContent span{
    width: 200px;
}
.cards .card:nth-child(3) .cardContent span{
    width: 200px;
}
.cards .card:nth-child(4) .cardContent span{
    width: 200px;
}
.cards .card .cardContent span{
    /* height: 150px; */
    /* background-color: red; */
    height: 150px;
    margin-bottom: 10px;
}
.cards .card .cardContent p{
    width: 80%;
    margin-top: 10px;
    line-height: 1.6;
}