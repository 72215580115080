.overlay{
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    /* background-color: var(--main-bg-color); */
    background-image: linear-gradient(to bottom, var(--main-bg-color), var(--main-color));
    opacity: 0.9;
    z-index: -1;
}
.overlay.dark{
    background-image: linear-gradient(to bottom, black, var(--main-color));
}