.why{
    padding: 20px 0px 60px;
    /* background-image: var(--main-gradient); */
    background-color: rgb(24, 24, 57);
    /* height: calc(100vh); */
    color: white;
}
.why > div h2{
    color: white;
}
.why .parts{
    display: grid;
    grid-template-columns: repeat(2,minmax(200px,1fr));
    column-gap: 20px;
    margin-top: 50px;
}
@media (max-width: 992px) {
    .why .parts{
        grid-template-columns: repeat(1,minmax(200px,1fr));
    }
}
.why .parts .part{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    /* margin-bottom: 10px; */
    transition: var(--main-transition);
    height: 150px;
}
.why .parts .part.reversed{
    flex-direction: row-reverse;
}
.why .parts .part .circle{
    width: 100px;
    height: 100px;
    background-color: rgba(255, 255, 255, 0.073);
    transition: var(--main-transition);
    border-radius: 50%;
}
.why .parts .part .text{
    width: 0px;
    transition: var(--main-transition);
    overflow: hidden;
    height: 150px;
    transition-delay: 0.3s;
    transition-duration: 0.6s;
    transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1);
    display: grid;
    place-content: center;
    padding-left: 40px;
    padding-right: 40px;
}
.why.animate .parts .part .text{
    /* width: 300px; */
    flex: 1;
    transition-delay: 0s;
}
.why .parts .part .text h3,
.why .parts .part .text p{
    opacity: 0;
    transition: var(--main-transition);
}
.why .parts .part .text h3{
    /* transform: translateY(-20px); */
}
.why .parts .part .text p{
    transform: translateY(20px);
    filter: blur(20px);
    transition-duration: 0.4s;
    color: rgb(178, 251, 255);
}
.why.animate .parts .part .text h3,
.why.animate .parts .part .text p{
    opacity: 1;
    transition-delay: 0.7s;
    transform: translateX(0);
    filter: blur(0px);
}
@media (max-width: 500px) {
    .why .parts .part{
        height: 250px;
    }
    .why .parts .part,
    .why .parts .part.reversed{
        flex-direction: column;
    }
    .why .parts .part .text{
        width: 100%;
        min-height: 150px;
    }
    .why .parts .part .text,
    .why .parts .part.reversed .text{
        text-align: center;
        padding-left: 0;
        padding-right: 0;
        height: 150px;
        place-content: start;
    }
    .why.animate .parts .part .text,
    .why.animate .parts .part.reversed .text{
        width: 100%;
    }
    .why .parts .part .circle{
        transform: scale(1.5) translateY(80px);
    }
    .why.animate .parts .part .circle{
        transform: scale(1) translateY(0px);
    }
}