header{
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    box-shadow: var(--main-shadow);
    transition: var(--main-transition);
    z-index: 20;
    background-color: transparent;
    backdrop-filter: blur(30px);
    border-bottom: 1px solid transparent;
}
header.bgWhite{
    background-color: var(--scroll-sec-bg-color);
    border-bottom: 1px solid var(--main-color);
    z-index: 20;
}
header > *{
    /* background-color: red; */
}
header .logo{
    width: 200px;
}

header nav{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    /* margin-right: 60px; */
    transition: var(--main-transition);
    transition-timing-function:cubic-bezier(0.23, 1, 0.320, 1);
    transition-duration: 0.7s;
}
header nav a{
    color: var(--text-color);
    font-weight: bold;
    font-size: 18px;
    text-transform: capitalize;
    transition: var(--main-transition);
    position: relative;
    border-radius: 20px;
    overflow: hidden;
    min-width: 120px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
}
header nav a span{
    z-index: 2;
    transform: translate(-50%,-50%);
    top: 50%;
    left: 50%;
    height: 100%;
    position: absolute;
    display: flex;
    align-items: center;
    padding: 0px 20px;
    justify-content: center;
    text-wrap: nowrap;
}
header nav a::before{
    position: absolute;
    content: "";
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    background-image: var(--main-gradient);
    height: 100%;
    width: 0%;
    transition: var(--main-transition);
    z-index: 1;
}
header nav a:hover{
    color: var(--hovered-text-color);
}
header nav a:hover::before{
    left: 0;
    right: auto;
    width: 100%;
}
header .cutomize{
    display: flex;
    align-items: center;
    gap: 10px;
}
header .changeTheme{
    background-color: var(--text-color);
    padding: 2px 10px;
    border-radius: 15px;
    display: flex;
    align-items: center;
    gap: 5px;    
    height: 30px;
    width: 50px;
    position: relative;
    transition: var(--main-transition);
    cursor: pointer;
}
header .changeTheme span{
    position: absolute;
    transition: var(--main-transition);
}
header .changeTheme span:nth-child(1){
    color: #3bd2f4;
    opacity: 0;
}
header .changeTheme span:nth-child(2){
    color: #ffff00;
}
header .changeTheme.dark span{
    right: 10px;
}
header .changeTheme.dark span:nth-child(1){
    opacity: 1;
}
header .changeTheme.dark span:nth-child(2){
    opacity: 0;
}
header .changeLanguage{
    background-color: var(--sec-bg-color);
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--text-color);
    cursor: pointer;
    padding: 0px 0px;
    height: 30px;
    border-radius: 20px;
    font-weight: bold;
}
header .changeLanguage span{
    width: 50%;
    height: 100%;
    text-align: center;
    background-color: var(--main-bg-color);
    display: flex;
    align-items: center;
    justify-content: center;
    border: 3px solid transparent;
}
header .changeLanguage span:nth-child(1){
    border-radius: 20px 0px 0px 20px;
}
header .changeLanguage span:nth-child(2){
    font-family: "Tajawal", sans-serif;
    border-radius: 00px 20px 20px 0px;
}
header .menuIcon{
    display: none;
}
@media (max-width:992px) {
    header nav{
        /* margin-right: 20px; */
        gap: 5px;
    }
    header nav a{
        font-size: 16px;
        min-width: 80px;
    }
    header .logo{
        width: 150px;
    }
}
@media (max-width:767px) {
    header{
    }
    header .logo{
        width: 150px;
    }
    header nav{
        margin-right: 0px;
        position: absolute;
        left: 50%;
        height: 0px;
        /* width: 70px; */
        top: calc(100% + 1px);
        transform: translateX(-50%);
        border-radius: 30px;
        gap: 0px;
        background-color: var(--main-bg-color);
        border: 2px solid var(--main-color);
    }
    header nav.active{
        height: 50px;
    }
    header nav a{
        /* overflow: hidden; */
        min-width: 100px;
        text-align: center;
        color: var(--text-color);
        height: 100%;
        border-radius: 0;
    }
    header nav a:nth-child(1){
        border-radius: 30px 0px 0px 30px;
    }
    header nav a:last-child{
        border-radius: 0px 30px 30px 0px;
    }
    header nav[language="ar"] a:nth-child(1){
        border-radius: 0px 30px 30px 0px;
    }
    header nav[language="ar"] a:last-child{
        border-radius: 30px 0px 0px 30px;
    }
    header nav a span{
        transition: var(--main-transition);
    }
    /* header nav a:nth-child(2) span{
        transition-delay: 0.5s;
    }
    header nav a:nth-child(3) span{
        transition-delay: 0.6s;
    }
    header nav a:nth-child(4) span{
        transition-delay: 0.7s;
    }
    header nav a:nth-child(5) span{
        transition-delay: 0.8s;
    } */
    header .menuIcon{
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--text-color);
        font-size: 24px;
        cursor: pointer;
        background-color: var(--main-bg-color);
        width: 40px;
        height: 40px;
        border-radius: 50%;
        position: absolute;
        left: 50%;
        top: 100%;
        transform: translate(-50%,-50%);
        z-index: 3;
        transition: var(--main-transition);
        border: 2px solid var(--main-color);
    }
    header .menuIcon.active{
        transform: translate(-50%,-100%);
    }
    header .menuIcon span{
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
    }
    header .menuIcon:hover{
        background-color: transparent;
        background-image: var(--main-gradient);
    }
}
@media (max-width:600px) {
    header .logo{
        width: 120px;
    }
    header nav{
        flex-direction: column;
        height: 300px;
        width: 200px;
        height: 0;
        overflow: hidden;
    }
    header nav.active{
        height: 320px;
    }
    header nav a{
        width: 100%;
    }
    header nav a:nth-child(1){
        border-radius: 30px 30px 0px 0px;
    }
    header nav a:last-child{
        border-radius: 0px 0px 30px 30px;
    }
    header nav[language="ar"] a:nth-child(1){
        border-radius: 30px 30px 0px 0px;
    }
    header nav[language="ar"] a:last-child{
        border-radius: 0px 0px 30px 30px;
    }
}