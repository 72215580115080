.footer{
    background-color: var(--text-color);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
    color: var(--main-bg-color);
}
.footer p{
    text-align: center;
}
.footer .socia a{
    color: var(--text-color);
    font-weight: bold;
    font-size: 16px;
    margin-left: 5px;
    margin-right: 5px;
    text-decoration: underline;
}
.footer .social{
    display: flex;
    align-items: center;
    gap: 10px;
}
.footer .social a span{
    color: var(--text-color);
    font-size: 20px;
    background-color: var(--main-bg-color);
    border-radius: 50%;
    border: 2px solid transparent;
    padding: 0px;
    display: flex;
    width: 45px;
    height: 45px;
    align-items: center;
    justify-content: center;
    transition: var(--main-transition);
    margin-top: 10px;
}
.footer .social a span:hover{
    border: 2px solid var(--main-color);
    /* font-size: 28px; */
    box-shadow: var(--hover-shadow);
    background-image: var(--main-gradient);
    transform: translateY(-3px);
}
.footer .info p[language]{
    font-size: 16px;
    font-style: italic;
    font-weight: bold;
    text-align: center;
    max-width: 400px;
}
.footer .info p a{
    font-weight: bold;
    text-decoration: underline;
    color: var(--main-bg-color);
    font-size: 16px;
}