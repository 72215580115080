.mission{
    /* height: 700px; */
    position: relative;
    z-index: 1;
    background-image: url("../../assests/home.jpg");
    padding-top: 100px;
    padding-bottom: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--text-color);
}
@media (max-width: 992px) {
    .mission{
        /* height: 120vh; */
    }
}
@media (max-width: 767px) {
    .mission{
        /* padding-top: 150px; */
    }
}
.mission .cards{
    width: 100%;
    /* height: 200px; */
    position: relative;
}
.mission .cards .card{
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    z-index: 2;
    max-width: 100%;
    position: relative;
}
.mission .cards .card:nth-child(1){
    flex-direction: row-reverse;
    margin-bottom: 40px;
}
.mission .cards .card h3{
    z-index: 2;
    font-family: "Maven Pro", sans-serif;
    font-weight: bold;
    position: absolute;
    opacity: 0;
    transition: opacity 0.2s 0.4s ease-out,
                transform 0.2s 0.4s ease-out,
                font-size 1s 1s ease-out,
    ;
    font-size: 80px;
    text-wrap: nowrap;
    animation: none;
}
.mission *[language="ar"] *{
    direction: ltr !important;
    text-align: right;
}
.mission *[language="ar"] h3{
    font-family: "Tajawal", sans-serif !important;
}
.mission .cards .card h3.animate{
    font-size: 46px;
    animation: h3-animation 1s cubic-bezier(0.075, 0.82, 0.165, 1) 1.2s 1 both;
    opacity: 1;
}
@media (max-width: 992px) {
    .mission .cards{
        padding-left: 20px;
        padding-right: 20px;
    }
    .mission .cards .card h3{
        font-size: 60px;
    }
    .mission .cards .card h3.animate{
        font-size: 36px;
    }
}
@media (max-width: 767px) {
    .mission .cards{
        padding-left: 40px;
        padding-right: 40px;
    }
    .mission .cards .card h3{
        font-size: 40px;
    }
    .mission .cards .card h3.animate{
        font-size: 20px;
    }
}
@media (max-width: 600px) {
    .mission .cards .card h3{
        font-size: 40px;
    }
    .mission .cards .card h3.animate{
        font-size: 24px;
    }
}
.mission .cards .card:nth-child(1) h3{
    /* left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%); */
    animation: h3-animation-sm 1s cubic-bezier(0.075, 0.82, 0.165, 1) 1.2s 1 both;
    border-bottom: 2px solid transparent;
    padding-bottom: 5px;
    /* animation: first-h3-animation 1s cubic-bezier(0.075, 0.82, 0.165, 1) 1.2s 1 forwards; */
}
.mission .cards .card h3.animate{
    animation: h3-animation-sm 1s cubic-bezier(0.075, 0.82, 0.165, 1) 1.2s 1 both;
}
.mission .cards .card:nth-child(2) h3{
    /* left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%); */
    padding-bottom: 5px;
    border-bottom: 2px solid transparent;
    /* animation: second-h3-animation 1s cubic-bezier(0.075, 0.82, 0.165, 1) 1.2s 1 forwards; */
}
.mission .cards .card:nth-child(1) h3.animate{
    /* top: -0%;
    transform: translateX(-50%) translateY(-110%); */
    /* transform: translateX(-50%) translateY(0px); */
}
.mission .cards .card:nth-child(2) h3.animate{
    /* top: -0%;
    transform: translateX(-50%) translateY(-110%); */
    /* transform: translateX(50%) translateY(0px); */
}
@media (max-width:600px) {
    .mission .cards .card:nth-child(1) h3{
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
        /* animation: h3-animation-sm 1s cubic-bezier(0.075, 0.82, 0.165, 1) 1.2s 1 both; */
        border-bottom: 2px solid transparent;
        padding-bottom: 5px;
        /* animation: first-h3-animation 1s cubic-bezier(0.075, 0.82, 0.165, 1) 1.2s 1 forwards; */
    }
    /* .mission .cards .card:nth-child(2) h3{
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
        animation: h3-animation-sm 1s cubic-bezier(0.075, 0.82, 0.165, 1) 1.2s 1 forwards;
        padding-bottom: 5px;
        border-bottom: 2px solid transparent;
    } */
}

.mission .cards .card p{
    padding-left: 20px;
    z-index: 2;
    font-size: 24px;
    width: calc(100% - 230px);
    /* transform: translateY(50px); */
    filter: blur(20px);
    /* opacity: 0; */
    transition: var(--main-transition);
    transition-delay: 1.2s;
}
@media (max-width: 992px) {
    .mission .cards .card p{
        font-size: 20px;
        width: calc(100% - 185px);
    }
}
@media (max-width: 767px) {
    .mission .cards .card p{
        font-size: 14px;
        width: calc(100% - 100px);
    }
}
@media (max-width: 600px) {
    .mission .cards{
        padding-left: 20px;
        padding-right: 20px;
    }
    .mission .cards .card p{
        font-size: 16px;
        width: calc(100%);
        padding: 0 !important;
        margin-bottom: 50px;
        text-align: center;
    }
}
@media (min-width: 600px) {
    .mission .cards .card:nth-child(1) h3{
        border-right: 2px solid var(--sub-main-color);
        transform: translateX(-50%) translateY(-60px);
        left: 50%;
        padding-right: 10px;
    }
    .mission .cards .card:nth-child(1) h3.animate{
        animation: first-h3-animation 1s cubic-bezier(0.075, 0.82, 0.165, 1) 1.2s 1 both;
    }
    .mission .cards .card:nth-child(2) h3.animate{
        border-left: 2px solid var(--sub-main-color);
        transform: translateX(50%)  translateY(60px);
        right: 50%;
        padding-left: 10px;
        animation: second-h3-animation 1s cubic-bezier(0.075, 0.82, 0.165, 1) 1.2s 1 forwards;
    }
    .mission .cards .card:nth-child(1) h3.animate{
        transform: translateX(-50%) translateY(0px);
    }
    .mission .cards .card:nth-child(2) h3.animate{
        transform: translateX(50%) translateY(0px);
    }
}
.mission .cards .card p.animate{
    filter: blur(0px);
}
.mission .cards .card:nth-child(1) p{
    padding-left: 20px;
}
.mission .cards .card:nth-child(2) p{
    padding-left: 0px;
    padding-right: 40px;
}
.mission .cards .card div:has(svg){
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    font-size: 150px;
    filter: blur(10px);
    opacity: 0;
    z-index: 1;
    transition: var(--main-transition);
    transition-delay: 1.2s;
}
.mission .cards .card:has(p.animate) div:has(svg){
    filter: blur(0px);
    opacity: 0.1;
}
.mission .cards .card:nth-child(1) div:has(svg){
    color: var(--main-color);
}
.mission .cards .card:nth-child(2) div:has(svg){
    color: var(--main-color);
}

@keyframes first-h3-animation {
    0%{
        transform: translateX(-50%) translateY(0px);
        left: 50%;
    }
    100%{
        transform: translateX(0%) translateY(0px);
        left: 0;
    }
}
@keyframes second-h3-animation {
    0%{
        transform: translateX(50%) translateY(0px);
        right: 50%;
    }
    100%{
        transform: translateX(0%) translateY(0px);
        right: 0;
    }
}
@keyframes h3-animation-sm {
    0%{
        left: 50%;
        top: 50%;
        border-bottom: 2px solid transparent;
        transform: translateX(-50%) translateY(-50%);
    }
    100%{
        left: 50%;
        top: 0%;
        border-bottom: 2px solid var(--sub-main-color);
        transform: translateX(-50%) translateY(-140%);
    }
}
